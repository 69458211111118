// CheckboxInput
export default ({label, name, value, handleChange}) => {
    const checked = value==1?true:false
    return(
        <p>
            <label>
            <input 
                type={'checkbox'} 
                checked={checked} 
                name={name} 
                onChange={handleChange}
            />
            &nbsp;<span>{label}</span>
            </label> 
        </p>
    )
}